.manageuserinfo-container{
    padding: 50px 20px;
    .line-b {
        height: 1px;
        width: 100%;
        background-color: #D8D8D8;
      }
    .user-info-header{
        width: 100%;
        height: auto;
        padding: 20px;
        border-radius: 8px;
        background-color: #2A2E49;
        .name{
            color: $white;
            font-size: 28px;
            font-family: $font-bold;
            line-height: 36px;
        }
        .email{
            color: $white;
            font-size: 14px;
            font-family: $font-regular;
            line-height: 20px;
        }
    }
    .profile-container{
        margin: 20px 0;
        background-color: $white;
        padding: 20px;
        border-radius: 8px;
        // display: flex;
        // flex-direction: row;
        .row{
            width :100%;
            margin-bottom: 10px;
        }
        .label{
            color: #999999;
            font-size: 14px;
            font-family: $font-regular;
        }
        .value{
            color: #030F09;
            font-size: 16px;
            font-family: $font-bold;
        }
    }
    .manageuser-parent{
        margin-top: 40px;
    }
}