.referral-view-main {
  .table th {
    vertical-align: middle;
  }
  .width-350 {
    width: 350px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
