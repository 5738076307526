
.tournament-container{
    .select-flex-display{
        display: flex;
        align-items: center;
        .filter-label{
        margin-right: 10px;
        }
    }
}
.playerattribute-container{
    .teampopup{
        cursor: pointer;
        color:#212235;
        text-decoration: underline;
    }
    .custom-radio{
        cursor: pointer;
        .custom-control-label{
            cursor: pointer;
            &::before{
                cursor: pointer;
            }
        }
    }
    .filter-userlist{
        margin-bottom: 0;
    }
    .user-list{
        margin-top: 20px;
    }
    .select-wrapper  { 
        display: flex;
        align-items: center;
        margin-top: 20px;
    }
    .select-flex-display{
       
        .filter-label{
            display: inline-block ;
            float: left;
            height: 32px;
            margin-top: 6px;
            margin-right: 11px;
         }
    }
}

//playerpopup modal
.playerdetailspopup{
    .model-body-container {
        text-align: left;
        padding: 30px;
        .player-name{
            color: #000000;
            font-family: "MuliBold";
            font-size: 35px;
        }
        .display-name-wrapper{
            display: flex;
            align-items: center;
            margin: 10px 0;
            .dis-span{
                color: #555555;
                font-family: "MuliBold";
                font-size: 16px;
            }
            .team-display-wrapper{
                margin-left: 10px;
                color: #000000;
                font-family: $Regular;
                font-size: 15px;

            }
        }
        .associated-team-wrapper{
            display: flex;
            align-items: center;
            margin: 25px 0;
            .team-span{
                color: #000000;
                font-family: "MuliBold";
                font-size: 20px;
            }
            .team-name-wrapper{
                margin-left: 10px;
                color: #000000;
                font-family: $Regular;
                font-size: 15px;

            }

        }
    }
    .select-wrapper{
        display: inline-block;
        width: 100%;
        margin: 0px 0 10px;
    }
    .addition-notes-wrraper{
        .team-span{
            color: #555555;
            font-family: "MuliBold";
            font-size: 15px;
        }
        textarea{
            margin: 25px 0 35px;
        }
    }
    .select-flex-display{
       
        .filter-label{
            display: inline-block ;
            float: left;
            height: 32px;
            margin-top: 6px;
            margin-right: 11px;
            color: #555555;
            font-family: "MuliBold";
            font-style: normal;
            margin-bottom: 4px;
            font-size: 15px;
            display: block;
            min-width: 50px;
            min-height: 21px;

         }
         .Select{
            width: 130px;
            float: left;
            margin-right: 16px;
         }
    }
    .button-wrapper{
         display: flex;
        align-items: center;
        justify-content: center;
        .submit-btn-style{
            margin: 0;
            &:first-child{
                margin-right: 10px;
            }

        }
    }

}