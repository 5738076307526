$full-grp-wdt : 1280px;
.br-none{
  border-right: none !important;
}
.pl-dashboard {
  .pl-col {
    flex: 0 0 50%;
    max-width: 50%;
    margin-top: 16px;
    padding-right: 8px;
    padding-left: 8px;
    .highcharts-container,
    .highcharts-root,
    .highcharts-background {
      width: 100% !important;
    }
    @media (max-width: 1200px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .test {
    width: 400px;
    height: 200px;
  }

  .pl-linechart {
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.05);
    min-height: 370px;
  }

  .pl-g-title-box {
    padding: 20px 20px 0px;
  }

  .pl-g-title {
    color: #000000;
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 20px;
  }

  .pl-g-subtitle {
    color: #999999;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }

  .pl-g-count {
    color: #E42054;
    font-size: 36px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 32px;
    margin-top: 4px;
  }

  .export-list {
    float: right;
    font-size: 24px;
    cursor: pointer;
    color: #999999;
    border: none;
    border-radius: none;
    box-shadow: none;
  }

  .pl-graph-box {
    padding: 0 10px;
  }

  .pl-to-date {
    margin-top: -4px;
    margin-left: 10px;
  }

  .refresh {
    font-size: 20px;
    color: #E42054;
    margin-left: 12px;
    margin-top: 8px;
    float: left;
    cursor: pointer;
  }
  .pl-seprate {
    margin: 40px -30px 30px !important;
  }
  .highcharts-legend-item{
    span{
      color: rgb(153, 153, 153) !important;
    }
  }
}

.pl-user-act {
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 14px;

  .pl-box-title {
    color: #000000;
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 16px;
    padding-left: 18px;
  }

  .pl-usr-title {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    float: left;
    margin-right: 10px;
    margin-top: 3px;
  }

  .pl-usr-count {
    color: #E42054;
    font-size: 20px;
    font-weight: 800;
    float: left;
  }

  .pl-user-act-item {
       /* float: left; */
       margin: 14px 0;
       display: inline-block;
       width: 33%;
       padding: 0 20px;
       vertical-align: top;

    &.pl-bdr {
      border-right: 1px solid #D8D8D8;
    }

    @media (max-width: $full-grp-wdt) {
      width: 50%;      
    }
  }  

  .pl-user-act-list{
    :last-child{
      margin-bottom: 0px;
    }
    :nth-child(3),
    :nth-child(5) {
      border-right: none;
    }
    @media (max-width: $full-grp-wdt) {
      :nth-child(2){
        border-right : none;
      }
      .pl-user-act-item:nth-child(3) {
        border-right: 1px solid #D8D8D8;
      }
    }
  }

  .pl-usr-body-item {
    display: inline-block;
    margin-right: 20px;
  }

  .pl-usr-body-count {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
  }

  .pl-usr-body-title {
    color: #999999;
    font-size: 12px;
    font-weight: 600;
  }  
}
.main-ind {
  display: block;
  height: 6px;
  width: 18px;
  border-radius: 3px;
  margin-top: 5px;
}

.indicator {
  @extend .main-ind;
  float: right;
  margin-left: 6px;
}

.dis-indicator {
  @extend .main-ind;
  float: left;
  margin-right: 6px;
}
.pl-subscription-count {
  color: #000000;
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 10px;
}
.action-btn{
  border: 1.52px solid #F8436E;
  border-radius: 3.05px;
  background-color: #F8436E;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
  padding: 8px 30px;
  text-align: center;
  cursor: pointer;
  &.ml20{
   margin-left: 20px;
  }
  span{
      color: $white;
      font-family: $font-bold;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
    //  line-height: 20px;
  }
}
.hide-div{
  display: none;
}