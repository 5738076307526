// .modal-dialog {
//   max-width: 750px;  
// }

.manage-bal {
  .bal-calculation {
    list-style-type: none;
    padding: 0;
    text-align: center;
  }

  .balance-items {
    margin: 0 22px;
    text-align: left;

    label {
      color: #555555;
      font-family: $Regular;
      font-size: 16px;
      font-style: inherit;
      font-weight: normal;
      margin: 0;
    }

    .numbers {
      color: $text-color;
      font-family: $font-bold;
      font-size: 20px;
      line-height: 25px;
      text-align: left;
    }
  }

  .cal-sign {
    line-height: 34px;
    font-size: 16px;
    color: $text-color;
    font-family: $Regular;
  }

  .modal-footer {
    border: 0;
    justify-content: center;
    padding: 20px;

    .btn-secondary-outline {
      // color: #070707;
      font-family: $font-bold;
    }
  }
}

.transaction-box {
  text-align: center;
  padding: 25px 16px 0;

  .transaction-type {
    color: $text-color;
    font-family: $font-bold;
    margin-bottom: 10px;
  }

  .money-type-list {
    list-style-type: none;
    margin: 0;
  }

  .money-type-item {
    display: inline-block;
    margin-right: 20px;

    label {
      font-style: inherit;
      font-family: $Regular;
      color: $text-color;
      font-weight: normal;
    }

    input[type="radio"] {
      height: 18px;
      width: 18px;
      vertical-align: middle;
    }
  }

  .amount-info {
    width: 298px;
    color: $text-color;
    font-family: $Regular;
    font-style: inherit;
    margin: 0 auto;
    text-align: center;
    margin-top: 60px;
  }

  .am-info-text {
    opacity: 0.5;
    color: $text-color;
    font-family: $Regular;
    font-size: 10px;
  }

  :focus {
    outline: none;
  }

  .floating-label {
    span {
      font-size: 14px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: normal;
      font-style: initial;
      color: $text-color;
    }

    input {
      border-bottom: 1px solid #D8D8D8;
      font-size: 14px;
    }

    :focus {
      outline: none;
      border-bottom: 1px solid #D8D8D8;
    }
  }
}

.reason-box {
  width: 298px;
  margin: 0 auto;
  margin-top: 36px;

  label {
    color: $text-color;
    font-family: $Regular;
    font-style: inherit;
  }

  .amo-reason {
    height: 110px;
    resize: none;
    padding: 12px;
    opacity: 0.7;
    color: #030F09;
    font-family: $Regular;
    line-height: 22px;
  }
}





// .ripple {
//   background-position: center;
//   transition: background 1s;
// }

// .ripple:hover {
//   background: #F8436E radial-gradient(circle, transparent 1%, #F8436E 1%) center/15000%;
// }

// .ripple:active {
//   background-color: #F8436E;
//   background-size: 100%;
//   transition: background 0s;
// }
