// Variable overrides


$Regular : 'MuliRegular' !default;
$font-black : 'MuliRegular';
$font-bolditalic:"Muli-BoldItalic";
$font-bold:"MuliBold";
$font-muliblack:"MuliBlack";
$font-bold-italic : "MuliItalic";
$font-semibold-italic : 'Muli-SemiBoldItalic';

$sidebar-width : 250px;
$theme-color : #2A2E49;

$body-bg : #EFEFEF;
$font-family : $Regular;

$white : #FFFFFF;
$black : #000000;
$red : #FF0101;

$success : #008743;
$pending : #ECA90E;
$failed : #F30000;

$text-color : #030F09; 
$secondary-color : #F8436E;   

$gray-9 : #999999;
$gray-5 : #555555;	

//media width
$width-992 : 992px;
$sidebar-margin-50 : 50px;