@font-face {
    font-family: "MuliBlack";
    src: url("../assets/fonts/MuliBlack.woff2") format("woff2"),
       url("../assets/fonts/MuliBlack.woff") format("woff"),
       url("../assets/fonts/MuliBlack.eot") format("embedded-opentype"),
       url("../assets/fonts/MuliBlack.ttf") format("truetype"),
       url('../assets/fonts/MuliBlack.eot?#iefix') format('embedded-opentype'),
       url("../assets/fonts/MuliBlack.svg#MuliBlack") format("svg");
     font-weight: normal;
}


@font-face {
   font-family: "MuliBold";
   src: url("../assets/fonts/MuliBold.woff2") format("woff2"),
   url("../assets/fonts/MuliBold.woff") format("woff"),
   url("../assets/fonts/MuliBold.eot") format("embedded-opentype"),
   url("../assets/fonts/MuliBold.ttf") format("truetype"),
   url('../assets/fonts/MuliBold.eot?#iefix') format('embedded-opentype'),
   url("../assets/fonts/MuliBold.svg#MuliBold") format("svg");
   font-weight: normal;
}

@font-face {
   font-family: "Muli-BoldItalic";
   src: url("../assets/fonts/Muli-BoldItalic.woff2") format("woff2"),
   url("../assets/fonts/Muli-BoldItalic.woff") format("woff"),
   url("../assets/fonts/Muli-BoldItalic.eot") format("embedded-opentype"),
   url("../assets/fonts/Muli-BoldItalic.ttf") format("truetype"),
   url('../assets/fonts/Muli-BoldItalic.eot?#iefix') format('embedded-opentype'),
   url("../assets/fonts/Muli-BoldItalic.svg#Muli-BoldItalic") format("svg");
   font-weight: normal;
}

@font-face {
   font-family: "MuliItalic";
   src: url("../assets/fonts/MuliItalic.woff2") format("woff2"),
   url("../assets/fonts/MuliItalic.woff") format("woff"),
   url("../assets/fonts/MuliItalic.eot") format("embedded-opentype"),
   url("../assets/fonts/MuliItalic.ttf") format("truetype"),
   url('../assets/fonts/MuliItalic.eot?#iefix') format('embedded-opentype'),
   url("../assets/fonts/MuliItalic.svg#MuliItalic") format("svg");
   font-weight: normal;
}

@font-face {
   font-family: "Muli-LightItalic";
   src : url("../assets/fonts/Muli-LightItalic.woff") format("woff"),
   url("../assets/fonts/Muli-LightItalic.eot") format("embedded-opentype"),
   url("../assets/fonts/Muli-LightItalic.ttf") format("truetype"),
   url('../assets/fonts/Muli-LightItalic.eot?#iefix') format('embedded-opentype'),
   url("../assets/fonts/Muli-LightItalic.svg#Muli-LightItalic") format("svg");
   font-weight: normal; 
}
@font-face {
   font-family: "MuliRegular";
   src: url("../assets/fonts/MuliRegular.woff") format("woff"),
   url("../assets/fonts/MuliRegular.eot") format("embedded-opentype"),
   url("../assets/fonts/MuliRegular.ttf") format("truetype"),
   url('../assets/fonts/MuliRegular.eot?#iefix') format('embedded-opentype'),
   url("../assets/fonts/MuliRegular.svg#MuliRegular") format("svg");
   font-weight: normal; 
}
@font-face {
   font-family: "MuliSemiBold";
   src: url("../assets/fonts/MuliSemiBold.woff") format("woff"),
   url("../assets/fonts/MuliSemiBold.eot") format("embedded-opentype"),
   url("../assets/fonts/MuliSemiBold.ttf") format("truetype"),
   url('../assets/fonts/MuliSemiBold.eot?#iefix') format('embedded-opentype'),
   url("../assets/fonts/MuliSemiBold.svg#MuliSemiBold") format("svg");
   font-weight: normal; 
}
@font-face {
   font-family: "Muli-SemiBoldItalic";
   src: url("../assets/fonts/Muli-SemiBoldItalic.woff") format("woff"),
   url("../assets/fonts/Muli-SemiBoldItalic.eot") format("embedded-opentype"),
   url("../assets/fonts/Muli-SemiBoldItalic.ttf") format("truetype"),
   url('../assets/fonts/Muli-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
   url("../assets/fonts/Muli-SemiBoldItalic.svg#Muli-SemiBoldItalic") format("svg");
   font-weight: normal; 
}