.manageuser-container{
    padding: 50px 20px;
    .line-b {
        height: 1px;
        width: 100%;
        background-color: #D8D8D8;
      }
    .manageuser-header{
        color: $black;
        font-family: $font-bold;
        font-size: 24px;
        font-weight: 800;
        letter-spacing: 0;
        line-height: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid #D8D8D8;
    }
    .manageuser-parent{
        margin-top: 40px;
        .filter-date {
            width: 100px;
            height: 36px;
            border-radius: 18px;
            border: none;
            box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
            text-align: center;
            font-family: $font-bold;
            color: $gray-9;
            font-size: 14px;
            cursor: pointer;
          }
          
          .filter-label {
            color: #555555;
            font-family: $font-bold;
            font-style: normal;
            margin-bottom: 4px;
            display: block;
            min-width: 50px;
            min-height: 21px;
          }
          
          .manage-user-heading {
            display: block;
            margin-bottom: 20px;
            margin-top: 26px;
          
            .page-title {
              float: left;
              color: #030f09;
              font-family: $font-muliblack;
              font-size: 32px;
              margin: 0;
            }
          
            .search-user {
              position: relative;
              float: right;
              border-radius: 18px;
          
              :focus {
                outline: none;
              }
          
              .search-input {
                height: 36px;
                width: 311px;
                border-radius: 18px;
                background-color: #ffffff;
                box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
                border: none;
                padding: 9px 18px;
                font-family: $font-bold;
                color: $gray-9;
              }
          
              i {
                position: absolute;
                top: 0;
                right: 0;
                padding: 11px 18px;
                font-size: 15px;
                cursor: pointer;
                color: #666666;
              }
            }
          }
          
         
          
          .user-list {
            text-align: left;
            margin-bottom: 76px;
            .component-loader,
            .no-records {
              min-height: 420px;

              width: 100%;
              text-align: center;
            }
            .user-name {
              font-weight: bold;
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }
            }
            .table {
              border: none;
              .icon-verified {
                font-size: 16px;
                margin-right: 4px;
                cursor: pointer;
          
                &.active {
                  color: #19caa8;
                }
              }
          
              .icon-email_verified {
                font-size: 20px;
                cursor: pointer;
          
                &.active {
                  color: #19caa8;
                }
              }
              .icon-pending-doc {
                font-size: 16px;
                color: #ff0000;
                margin-right: 4px;
              }
              .icon-inactive-border {
                font-size: 23px;
                cursor: pointer;
          
                &.active {
                  color: #ff0000;
                }
              }
          
              .icon-wallet {
                font-size: 21px;
                cursor: pointer;
              }
          
              .icon-flag {
                font-size: 22px;
              }
          
              thead {
                background-color: $white;
                border: none;
                background-color: #2b2e47;
                box-shadow: 0 13px 26px 0 rgba(0, 0, 0, 0.1);
                color: $white;
          
                th {
                  padding: 4px;
                  font-family: $font-bold;
                  font-style: normal;
                  i{
                    padding: 5px;
                    cursor: pointer;
                    font-size: 20px;
                  }
                }
              }
          
              .left-th {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                padding-left: 20px !important;

              }
          
              .right-th {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
              }
          
              &:first-child:before {
                content: "@";
                display: block;
                line-height: 6px;
                text-indent: -99999px;
              }
          
              tbody {
                border-top: none;
                background-color: $white;
                box-shadow: 0 13px 26px 0 rgba(0, 0, 0, 0.1);
          
                &:nth-child(odd) {
                  background-color: #efefef;
                }
          
                td {
                  border: none;
                  color: #555555;
                  font-family: $Regular;
                  padding: 10px;
                  font-size: 16px;
                  text-align: left;
                  &.clickable{
                    cursor: pointer !important;
                  }
                }
          
                .kyc-status {
                  font-size: 14px;
                  font-weight: bold;
          
                  .verify {
                    color: #19caa8;
                    font-style: normal;
                    cursor: pointer;
                  }
                }
              }
            }
         
          }
          
          .filter-userlist {
            margin-bottom: 20px;
            .Select-clear-zone {
              display: none;
            }
            .has-value,
            .Select-value-label {
              font-family: $font-bold;
              color: #999999 !important;
            }
            .member-box {
              float: left;
              margin-right: 60px;
            }
            .clear-date-filter{
              margin-top: 10px;
              text-align: center;
              font-family: $font-bold;
              color: $gray-9;
              font-size: 14px;
              cursor: pointer;
              text-decoration: underline;
            }
            .react-datepicker-wrapper {
              margin-right: 10px;
            }
          
            .react-datepicker-wrapper,
            .react-datepicker__input-container {
              :active,
              :focus {
                outline: none;
              }
            }
          
            .Select-arrow {
              &::after {
                right: 12px;
                top: 10px;
                font-size: 12px;
                width: 16px !important;
                height: 16px !important;
              }
            }
          .subscription-select{
            .Select {
              width: 150px;
             
            }
          }
            .Select {
              width: 130px;
              float: left;
              margin-right: 16px;
          
              .Select-placeholder {
                padding-left: 14px;
                padding-right: 30px;
              }
          
              :active,
              :focus {
                outline: none;
              }
            }
          
            .pending-docs,
            .pending-docs1 {
              float: left;
              padding: 6px 19px;
              cursor: pointer;
              border: 1px solid #2b2e47;
              opacity: 0.9;
              border-radius: 18px;
              box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
              font-family: $font-bold;
              color: #555555;
              margin-right: 16px;
            }
          
            .pending-docs1 {
              border: 1px solid #f8436e !important;
            }
          
            .pending-docs1 {
              float: left;
              padding: 6px 19px;
              cursor: pointer;
              border: 1px solid #2b2e47;
              opacity: 0.9;
              border-radius: 18px;
              box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
              font-family: $font-bold;
              color: #555555;
            }
          
            .Select-menu-outer {
              margin-top: 2px;
              border-radius: 0 0 24px 24px;
              border: none;
              box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
              div 
              {
                border-radius: 0 0 24px 24px;
                background-color: transparent;
                &:hover {
                  background-color: transparent;
                  border-radius: 0 0 24px 24px;
                  // background: red;
                }
              }
            }
          
            .Select-control {
              height: 36px;
              margin: 0;
              border: none;
              border-radius: 18px;
              background-color: #ffffff;
              box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
              .Select-value-label {
                line-height: 38px;
              }
            }
            .search-user {
              position: relative;
              float: right;
              border-radius: 18px;
          
              :focus {
                outline: none;
              }
          
              .search-input {
                height: 36px;
                width: 311px;
                border-radius: 18px;
                background-color: #ffffff;
                box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
                border: none;
                padding: 9px 18px;
                font-family: $font-bold;
                color: $gray-9;
              }
          
              i {
                position: absolute;
                top: 0;
                right: 0;
                padding: 11px 18px;
                font-size: 15px;
                cursor: pointer;
                color: #666666;
              }
            }
          }
          
          .export-list {
            float: right;
            border: 1px solid rgba(0, 0, 0, 0.5);
            border-radius: 4px;
            box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
            padding: 8px;
            font-size: 18px;
            cursor: pointer;
          }
          
          .custom-pagination {
            padding-left: 15px;
          
            .pagination {
              margin-bottom: 0;
          
              .page-link {
                color: #f8436e;
              }
          
              .page-item.active .page-link {
                background-color: #f8436e;
                border: 1px solid #f8436e;
                color: #fff;
              }
          
              //added for userlist page
              > :first-child {
                border-left: 1px solid #bbbbbb;
              }
          
              li {
                padding: 4px 11px;
                border-right: 1px solid #bbbbbb;
                border-top: 1px solid #bbbbbb;
                border-bottom: 1px solid #bbbbbb;
                background-color: #f9f9f9;
                box-shadow: 0 1px 1px 0 rgba(221, 221, 221, 0.5);
          
                &.active {
                  background-color: #efefef;
                  a {
                    color: #9b9b9b;
                  }
                }
              }
          
              //added for userlist page
              li a {
                color: #f8436e;
                font-size: 12px;
              }
            }
          }
          .card-box {
            min-height: 574px;
          }
          .btn-done {
            float: right;
          }
          
    }
}