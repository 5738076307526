.add-match-information-container {
    padding: 50px 0;

    .add-match-information-header {
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #D8D8D8;
        display: flex;

        .tab-item {
            color: $black;
            font-family: $font-bold;
            font-size: 16px;
            font-weight: 800;
            letter-spacing: 0;
            line-height: 22px;
        }
    }

    .main-container {
        margin-top: 50px;
        display: flex;
        width: 100%;
        max-width: 900px;
        position: relative;

        .toss-container {
            width: 100%;

            .heading-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .title-view {
                    display: flex;
                    flex-direction: column;
                    flex: 1;

                    .txt-title {
                        color: $black;
                        font-family: $font-bold;
                        font-size: 16px;
                        font-weight: 800;
                        letter-spacing: 0;
                        line-height: 22px;
                    }

                    .txt-desc {
                        color: $black;
                        font-family: $Regular;
                        font-size: 12px;
                        font-weight: 400;
                        letter-spacing: 0;
                        line-height: 18px;
                    }
                }

                
            }

            .margin-top-20 {
                margin-top: 20px;
            }

            .toggle-containar {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .txt-toggle-name {
                    color: $black;
                    font-family: $font-bold;
                    margin-left: 15px;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0;
                    line-height: 21px;
                }

                .toggle-view {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: #999999;
                    width: 30%;
                    height: 40px;
                    border-radius: 15px;
                    overflow: hidden;

                    .first-view {
                        width: 49.7%;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #f7f7f7;
                        cursor: pointer;

                        .txt-first-name {
                            color: $black;
                            font-family: $font-bold;
                            font-size: 14px;
                            font-weight: 400;
                            letter-spacing: 0;
                            line-height: 21px;
                        }

                        .active-txt-clr {
                            color: white;
                        }
                    }

                    .active-bg-clr {
                        background-color: red;
                    }

                }

            }
        }
    }

    .win-container {
        margin-top: 20px;
        width: 100%;
        max-width: 900px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .item-win-container {
            display: flex;
            flex-direction: row;
            margin-top: 15px;

            .text-home {
                color: $black;
                font-family: $font-bold;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 21px;
            }

            .mrg-lft {
                margin-left: 5px;
            }

            .input-win {
                height: 30px;
                border: 1px solid #D2DFEC;
                border-radius: 4px;
                background-color: #EDEDED;
                padding: 0 10px;
                width: 80px;
                background-color: white;
                font-family: $font-regular;
                margin-left: 5px;
                margin-top: -5px;
            }
            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button { 
                -webkit-appearance: none;
            } 
        }
    }
    .team-container {
        margin-top: 20px;
        width: 100%;
        max-width: 900px;
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
       
        .exclude-container{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .item-exclude{
                display: flex;
                flex-direction: row;
                margin-right: 50px;
                cursor: pointer;
                .view-circle{
                    width: 20px;
                    height: 20px;
                    border-radius: 5px;
                    border: 1px solid #D2DFEC;
                    margin-right: 10px;
                    background-color: white;
                }
                .filledCirecle{
                    background-color: red;
                }
                .text-exclude{
                    color: $black;
                    font-family: $font-regular;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0;
                    line-height: 21px;
                    opacity: 0.8;
                }
            }
            
        }
        .item-win-container {
            display: flex;
            flex-direction: column;
            position: relative;
            .fixed-value{
                position: absolute;
                top: 57px;
                left: 8px;
                width: 97%;
                min-height: 80px;
                background-color: white;
                .full_name{
                    color: $black;
                    font-family: $font-regular;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0;
                    line-height: 21px;
                    opacity: 0.8;
                    margin-top: 8px;
                    margin-left: 8px;
                    cursor: pointer;
                }
            }
            .text-home {
                color: $black;
                font-family: $font-bold;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 21px;
                margin-bottom: 10px;
            }

            .mrg-lft {
                margin-left: 5px;
            }

            .input-win {
                margin-top: 15px;
                margin-bottom: 15px;
                height: 30px;
                border: 1px solid #D2DFEC;
                border-radius: 4px;
                background-color: #EDEDED;
                padding: 0 10px;
                width: 100%;
                max-width: 220px;
                background-color: white;
                font-family: $font-regular;
                margin-left: 5px;
                margin-top: -5px;
            }
        }
    }
    .border-line {
        width: 100%;
        max-width: 900px;
        height: 1px;
        background-color: #D2DFEC;
        margin-top: 40px;
    }
    .txt-edit {
        color: $black;
        font-family: $Regular;
        margin-left: 15px;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 21px;
        text-transform: uppercase;
    }

    .active-edit {
        color: red;
        cursor: pointer;
        text-decoration: underline;
    }
    .player-container{
        display: flex;
        width: 100%;
        max-width: 900px;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
        .player-name{
            color: $black;
            font-family: $Regular;
            margin-left: 15px;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 21px;
            width: 25%;
            float: left;
        }
        .bold{
            font-family: $font-bold; 
            margin-bottom: 10px;
        }
        .player-desc{
            color: $black;
            font-family: $Regular;
            margin-left: 15px;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 21px;
            width: 40%;
            float: left;
        }
        .player-desc-bold{
            color: $black;
            font-family: $font-bold; 
            margin-left: 15px;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 21px;
            width: 40%;
            float: left;
        }
    }
}