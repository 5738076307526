.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pending-doc {
  box-sizing: border-box;
  height: 36px;
  border: 2px solid rgba(255, 0, 0, 0.4);
  background-color: $white;
  text-align: center;
  color: $black;
  font-family: $font-bold;
  padding: 6px;

  i {
    vertical-align: initial;
    font-size: 15px;
  }

  .verify {
    color: $red;
    font-family: $font-bold;
    margin-left: 22px;
    text-decoration: underline;
    cursor: pointer;
  }
}

.profile-header {
  height: 100px;
  border-radius: 6px;
  background-color: #2a2e49;
  box-shadow: 0 13px 26px 0 rgba(0, 0, 0, 0.1);
  margin-top: 28px;
  display: flex;
  align-items: center;

  .profile-box {
    // padding: 0px 30px;
    display: table;
    align-items: center;
  }

  .profile-container {
    height: 80px;
    width: 80px;
    color: $white;
    text-align: left;
    box-shadow: 0 7px 22px 0 rgba(0, 0, 0, 0.1);
    display: table-cell;
    min-width: 80px;
    img {
      border-radius: 100%;
    }
  }

  .info-box {
    color: $white;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    padding-left: 20px;
    i.icon-flag {
      font-size: 24px;
      margin-left: 16px;
    }
  }

  .name {
    font-family: $font-bold;
    font-size: 28px;
    line-height: 40px;
    white-space: nowrap;
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .address {
    font-family: $font-bold;
    font-size: 12px;
  }
}

.profit-earned {
  color: $white;
  font-family: $font-bold;

  .rupees {
    font-size: 28px;
    line-height: 40px;
  }
}

.action-list {
  // display: inline-block;
  list-style-type: none;
  float: right;
  margin-top: 20px;

  .action-item {
    float: left;
    margin-right: 20px;
    color: $white;
    cursor: pointer;

    i {
      font-size: 16px;
      padding: 0;
      margin: 0;
    }

    .icon-notify {
      font-size: 24px;
    }
  }

  .action-item-box {
    height: 36px;
    width: 36px;
    border: 1px solid #ffffff;
    border-radius: 4px;
    padding: 4px 8px;
    &.active{
      color: $red;
      border: 1px solid $red;
    }
  }

  .noti-padd {
    padding: 4px 3px;
  }

  .action-title {
    opacity: 0.5;
    font-family: $font-bold;
    font-size: 7px;
    line-height: 9px;
    text-align: center;
    margin-top: 6px;
  }
}

.user-navigation {
  height: 48px;
  border-radius: 6px;
  background-color: $white;
  box-shadow: 0 13px 26px 0 rgba(0, 0, 0, 0.1);
  margin-top: 20px;

  .tab-content {
    border: none;
    background: transparent;
    // margin-top: 30px;

    .tab-pane {
      padding: 0;
    }
  }

  .nav-tabs,
  .nav-link,
  .nav-link.active {
    border-color: transparent;
    background-color: transparent;
    border: none;
    padding: 1px;
    // justify-content: center;
  }

  .nav-link {
    // padding: 14px;
    padding: 0;
    display: block;

    &:not(.active) {
      color: #a8a8a8;
      font-family: $font-bold;
      font-size: 18px;
    }

    &.active {
      color: #f8436e;
      // padding: 14px;
      padding: 0;
      font-size: 18px;
      font-family: $font-bold;

      &::after {
        content: "";
        display: block;
        line-height: 6px;
        width: 100%;
        border-bottom: 2px solid #f8436e;
        padding-top: 8px;
        width: 100%;
      }
    }
  }

  // .nav-tabs {
  //   >li:first-child {
  //     margin-left: 0;
  //     padding-left: 0;
  //   }
  // }

  .nav-item {
    // padding: 0 40px;
    padding: 10px 40px;
    height: 48px;

    @media (max-width: 1690px) {
      padding: 10px 30px;
    }

    // @media (max-width: 1367px) {
    //   padding: 10px 10px;
    // }

    &:hover {
      .nav-link {
        color: #f8436e;

        &::after {
          content: "";
          display: block;
          line-height: 6px;
          width: 100%;
          border-bottom: 2px solid #f8436e;
          padding-top: 8px;
          width: 100%;
        }
      }
    }
    &.active{
      .nav-link {
        color: #f8436e;

        &::after {
          content: "";
          display: block;
          line-height: 6px;
          width: 100%;
          border-bottom: 2px solid #f8436e;
          padding-top: 8px;
          width: 100%;
        }
      }
    }

  }
}

.add-notes {
  label {
    color: $text-color;
    font-family: $Regular;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 12px;
    margin-bottom: 2px;
  }
  .react-datepicker-wrapper {
    display: block;
  }
  .note-desc {
    resize: none;
    height: 100px;
  }

  .react-datepicker__input-container {
    float: left;
    max-width: 120px;
  }

  .flags {
    float: right;

    i {
      font-size: 15px;
    }

    img {
      height: 16px;
      width: 12px;
      vertical-align: inherit;
    }
  }

  .flag-box {
    display: inline-block;
    height: 24px;
    width: 24px;
    border: 1px solid #999999;
    border-radius: 2px;
    padding: 3px 7px;
    margin-left: 16px;
    cursor: pointer;
    &.icon-flag {
      color: $gray-9;
      border-color: $gray-9;
    }
    &.active {
      border-color: $red;
    }
  }

  .disable-flag {
    display: inline-block;
    vertical-align: bottom;
  }
}
