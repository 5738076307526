  .app-banner-img {
    width: 50px;
    height: 50px;
    margin: 0;
  }
.app-banner {
  .table {
    td {
      vertical-align: middle;
    }
  }
  .icon-verified.active {
    color: #19caa8;
  }
  .icon-inactive {
    color: $red;
  }
  .dropdown-item:active{
    background-color: #2A2E49;
  }
}
