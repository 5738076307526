.new-banner {
  background-color: $white;
  padding: 40px 20px;
  margin-top: 20px;
  .b-input-label {
    font-family: $font-bold;
  }
  .form-control,
  .Select-input {
    height: 42px;
  }
  .Select-placeholder,
  .Select-value-label {
    line-height: 42px;
  }
  .Select-control {
    border-color: #e4e7ea;
  }
  i.icon-Shape {
    position: absolute;
    top: 0;
    right: 0;
    width: 42px;
    z-index: 1;
    height: 42px;
    padding: 12px 0px;
  }
  .input-row {
    margin-top: 16px;
  }
  .banner-action {
    margin-top: 40px;
  }
  .banner-img {
    width: 320px;
  }
  .error-text {
    font-size: 12px;
    color: $red;
    font-family: $Regular;
    margin-top: 6px;
  }
  .icon-inactive {
    color: $red;
  }
  .icon-verified.active {
    color: #19caa8;
  }
  .lobby-banner-img {
    width: 150px;
    height: 60px;
    margin: 0;
  }
}
.lobby-paging {
  float: right;
  margin: 20px 0 40px 0;
}
