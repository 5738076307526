.simulator-container{
    padding: 50px 20px;
    .simulator-header-container{
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        .simulator-header{
            color: $black;
            font-family: $font-bold;
            font-size: 24px;
            font-weight: 800;
            letter-spacing: 0;
            line-height: 30px;
        }
        .simulator-fixture-info{
            color: $black;
            margin-left: 10px;
            font-family: $font-bold;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 30px;
        }
    }
    .simulator-desc-container{
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        .simulator-desc{
            color: $black;
            font-family: $font-regular;
            font-size: 16px;
            font-weight: 800;
            letter-spacing: 0;
            line-height: 30px;
        }
    }
    .simulator-parent{
        .create-simulator-container{
            display: flex;
            flex-direction: row;
            background-color: $white;
            border-radius: 12px;
            @media screen and(max-width:1024px){
                flex-direction: column;
            }
            // width: 50%;
            // @media screen and(max-width:767px){
            //     width: 100%;
            // }
            // background-color: $white;
            // border-radius: 12px;
            // margin-top: 20px;
            .parent-box{
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: center;
                padding-bottom: 20px;
                .full-width-container{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    @media screen and(max-width:1024px){
                        flex-direction: column;
                    }
                    .inner-container{
                        width: 50%;
                        @media screen and(max-width:1024px){
                            width: 100%;
                            margin-top: 20px;
                            border-left: 0p;
                            border-top: 1px solid #EFEFEF;
                        }
                        margin-top: 20px;
                        padding: 20px;
                        &.overlay{
                            background-color: #33333380;
                            z-index: 999;
                            
                        }
                        .child-item{
                            margin-top: 20px;
                            input[type=number] {
                                -moz-appearance: textfield !important;
                            }
                            input::-webkit-outer-spin-button,
                            input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                            }
                            h3{
                                background-color: #F8436E;
                                color: $white;
                                padding: 5px;
                                border-radius: 8px;
                                text-align: center;
                            }
                            .title{
                                color: #555555;
                                font-family:  $font-bold;
                                font-size: 14px;
                                font-weight: bold;
                                letter-spacing: 0;
                                line-height: 20px;
                                &.right-align{
                                    text-align: right;
                                    cursor: pointer;
                                }
                            }
                            .league_name{
                                color: #000;
                                font-family:  $font-bold;
                                font-size: 18px;
                                font-weight: bold;
                                letter-spacing: 0;
                                text-align: left;
                                text-transform: uppercase;
                                margin-bottom: 10px;
                            }
                            .leagueContainer_details{
                                margin-top: 10x;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                .league_desc_title{
                                    color: #000;
                                    font-family:  $font-bold;
                                    font-size: 14px;
                                    font-weight: bold;
                                    letter-spacing: 0;
                                    text-align: left;
                                }
                                .league_desc_name{
                                    margin-left: 5px;
                                    color: #000;
                                    font-family:  $font-regular;
                                    font-size: 14px;
                                    letter-spacing: 0;
                                    text-align: left;
                                }
                            }
                            
                        
                            .input-style{
                                margin-top: 10px;
                                height: 40px;
                                border: 1px solid #D2DFEC;
                                border-radius: 4px;
                                background-color: #EDEDED;
                                padding: 0 5px;
                                width: 100%;
                                font-family: $font-regular;
                                &.error{
                                    border: 1px solid #FF0000;
                                }
                            }
                        .sub-title{
                                color: #555555;
                                font-family:  $font-bold;
                                font-size: 12px;
                                font-weight: bold;
                                letter-spacing: 0;
                                line-height: 20px;
                                text-align: center;
                        }
                        &.right-align{
                            text-align: right;
                        }
                        i{
                            cursor: pointer;
                            padding: 5px;
                        }
                        }
                        .prize-list{
                            max-height: 400px;
                            overflow-y: auto;
                            overflow-x: hidden;
                        }
                        .btn-center-style{
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            
                        }
                        .submit-item{
                            margin-top: 30px !important;
                            justify-content: center;
                        }
                        .selected-file{
                            margin: 20px 0;
                            padding: 10px;
                            border: 1px dashed #212121;
                            border-radius: 4px;
                            border-radius: 10px;
                            background-color: #EFEFEF;
                            box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
                            position: relative;
                            .selected-file-item{
                                color: #017AFF;
                                cursor: pointer;
                                :last-child{
                                    margin-top: 10px;
                                }
                            }
                            .selected-label{
                                color: $black;
                                font-family: $font-regular;
                                font-size: 14px;
                                letter-spacing: 0;
                                line-height: 18px;
                            }
                            .remove-label{
                                position: absolute;
                                right: 10px;
                                top: 10px;
                                color: #FF0000;
                                font-family: $font-regular;
                                font-size: 14px;
                                letter-spacing: 0;
                                line-height: 18px;
                                cursor: pointer;
                                text-decoration: underline;
                            }
                            .delete-pdf-option{
                                font-size: 14px;
                                color: $primary;
                                font-family: $font-regular;
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }
                        .upload-pdf-container{
                            margin: 20px 0;
                            border: 1px dashed #212121;
                            border-radius: 4px;
                            border-radius: 10px;
                            background-color: #FFFFFF;
                            box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
                            text-align: center;
                            display: flex;
                            align-items: center;
                            .small-thumb-style{
                                margin: 10px;
                                width: 40px;
                                height: 40px;
                            }
                            .add-image-label{
                                color: #999999;
                                font-family: $font-regular;
                                font-size: 14px;
                                letter-spacing: 0;
                                line-height: 18px;
                                text-align: center;
                                span{
                                    color: #017AFF;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .player-compare-container{
                        width: 50%;
                        border-left: 1px solid #EFEFEF;
                        .um-plist{
                            ul{
                                li{
                                    display: inline-block;
                                    width: 49%;
                                    vertical-align: middle;
                                    span{
                                        white-space: nowrap;
                                        overflow: hidden;
                                        max-width: 90%;
                                        display: inline-block;
                                        vertical-align: middle;
                                        text-overflow: ellipsis;
                                    }
                                    @media (max-width: 1200px){
                                        width: 100%;
                                    }
                                }
                            }
                        }
                        @media screen and(max-width:1024px){
                            width: 100%;
                            margin-top: 20px;
                            border-left: 0p;
                            border-top: 1px solid #EFEFEF;
                        }
                        padding: 20px;
                        .compare-header{
                            text-align: center;
                            width: 100%;
                            margin: 20px 0px;
                            font-size: 18px;
                            color: #333333;
                        }
                        .player-maping-list{
                            max-height: 100vh;
                            overflow-y: auto;
                            padding-bottom: 50px;
                            .list-item{
                                display: flex;
                                flex-direction: row;
                                margin-top: 10px;
                            .header{
                                color: #888888;
                                font-size: 16px;
                                width: 50%;
                            }
                            .pl-player-box{
                                    width: 49%;
                                    border: 1px solid #212341;
                                    margin-right: 2%;
                                    padding: 5px !important;
                                    background-color: #EFEFEF;
                                    border-radius: 4px;
                                    .player-name{
                                        color: #333333;
                                        font-size: 16px;
                                        font-family: $font-bold;
                                    }
                                    .player-team{
                                        color: #333333;
                                        font-size: 12px;
                                        font-family: $font-regular;
                                    }
                                }
                                .dream-player-box{
                                    width: 49%;
                                    .input-view{
                                        width: 100%;
                                        height: 55px;
                                        border-radius: 4px;
                                        border: 1px solid #212341;
                                    }
                                    .Select-control{
                                        border: 1px solid #212341 !important;
                                        height: 54px !important;
                                    }
                                    .Select{
                                        :focus{
                                            box-sizing: unset !important; 
                                        }
                                        box-sizing: unset !important; 
                                    }
                                    .Select-placeholder{
                                        top: 10px !important;
                                    }
                                    .Select-value{
                                        top: 10px !important;
                                    }
                                    .Select-input{
                                        position: absolute;
                                        top: 10px !important;
                                    }
                                }
                            }
                        }
                        .disable-btn{
                            background-color: #E3E3E3 !important;
                            color: #555555;
                        }
                        .btn-style{
                            min-width: 160px !important;
                        }
                        .btn-center-style{
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
            .player-compare-container{
                width: 50%;
                border-left: 1px solid #EFEFEF;
                @media screen and(max-width:1024px){
                    width: 100%;
                    margin-top: 20px;
                    border-left: 0p;
                    border-top: 1px solid #EFEFEF;
                }
                padding: 20px;
                .compare-header{
                    text-align: center;
                    width: 100%;
                    margin: 20px 0px;
                    font-size: 18px;
                    color: #333333;
                }
                .player-maping-list{
                    max-height: 100vh;
                    overflow-y: auto;
                    padding-bottom: 50px;
                    .list-item{
                        display: flex;
                        flex-direction: row;
                        margin-top: 10px;
                       .header{
                           color: #888888;
                           font-size: 16px;
                           width: 50%;
                       }
                       .pl-player-box{
                            width: 49%;
                            border: 1px solid #212341;
                            margin-right: 2%;
                            padding: 5px !important;
                            background-color: #EFEFEF;
                            border-radius: 4px;
                            .player-name{
                                color: #333333;
                                font-size: 16px;
                                font-family: $font-bold;
                            }
                            .player-team{
                                color: #333333;
                                font-size: 12px;
                                font-family: $font-regular;
                            }
                        }
                        .dream-player-box{
                            width: 49%;
                            .Select-control{
                                border: 1px solid #212341 !important;
                                height: 54px !important;
                            }
                            .Select{
                                :focus{
                                    box-sizing: unset !important; 
                                }
                                box-sizing: unset !important; 
                            }
                            .Select-placeholder{
                                top: 10px !important;
                            }
                            .Select-value{
                                top: 10px !important;
                            }
                            .Select-input{
                                position: absolute;
                                top: 10px !important;
                            }
                        }
                    }
                }
                .disable-btn{
                    background-color: #E3E3E3 !important;
                    color: #555555;
                }
                .btn-style{
                    min-width: 160px !important;
                }
                .btn-center-style{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .margin-right{
        margin-left: 5px;
    }
}