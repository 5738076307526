.transaction-list {
  margin-top: 1.5rem;
  .react-datepicker-wrapper {
    display: block;
  }
  .form-control {
    border-color: #cccccc;
  }
  .form-check-input {
    margin: 0;
    padding: 0;
    position: relative;
  }
  .user-name {
    cursor: pointer;
    text-decoration: underline;
  }
  .table {
    th {
      vertical-align: middle;
    }
  }
  .dropdown-menu.show {
    left: -25px !important;
  }
}
.email-modal {
  label {
    margin-top: 10px;
    margin-bottom: 4px;
  }
  textarea {
    resize: none;
  }
}
.balance-modal {
  textarea {
    resize: none;
  }
  input[type="radio"] {
    z-index: 1;
    margin-top: 0px;
    left: 2px;
    top: 5px;
    cursor: pointer;
  }
  .space {
    margin-top: 18px;
    margin-bottom: 4px;
  }
  .custom-control-label {
    font-family: $Regular;
  }
}
.wd-100 {
  width: 100px;
}
