.cms-page-view {
  margin-top: 30px;
  .edit-title {
    background-color: $theme-color;
    color: $white;
    font-size: 16px;
    padding: 10px 30px;
    font-family: $Regular;
  }
}
