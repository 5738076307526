.lineupout-container{
    padding: 50px 20px;
    .line-b {
        height: 1px;
        width: 100%;
        background-color: #D8D8D8;
      }
    .lineupout-header{
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #D8D8D8;
        display: flex;
        .tab-item{
            color: $black;
            font-family: $font-bold;
            font-size: 16px;
            font-weight: 800;
            letter-spacing: 0;
            line-height: 22px;
            margin-right: 10px;
            cursor: pointer;
            &.selected{
                border-bottom: 2px solid $primary;
                color: $primary;
            }
        }
    }
    .view-right{
        position: absolute;
        bottom:5px;
        right:5px;
        width: 100px;
    }
    .lineupout-parent{
        margin-top: 40px;
        display: block;
        .fixture-item{
            margin: 15px 40px 0 0;
            display: inline-block;
            width: 320px;
            background-color: #FFFFFF;
            border: 1px solid #EFEFEF;
            border-radius: 10px;
            padding: 20px;
            position: relative;
            .fixture-info{
                display: flex;
                text-align: center;
                padding-top: 5px;
                img{
                    display: inline-block;
                    width: 36px;
                    height: 36px;
                }
                .fixture-center-box{
                    display: inline-block;
                    min-width: 170px;
                    flex: 1;
                    .fixture-name{
                        margin:0px 8px 0px 8px;
                        display: block;
                        color: #212121;
                        font-family: $font-bold;;
                        font-size: 15px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 19px;
                        text-align: center;
                    }
                    .fixture-date{
                        margin: 0 10px;
                        display: block;
                        color: #999999;
                        font-family: $font-regular;
                        font-size: 10px;
                        font-weight: 300;
                        letter-spacing: 0;
                        line-height: 13px;
                        text-align: center;
                    }
                }
                &.enabled{
                    cursor: pointer;
                }
            }
            .simulator-status-box{
                position: absolute;
                left: 0px;
                top: 0px;
                background-color: $primary;
                padding: 2px 5px;
                border-top-left-radius: 10px;
                font-size: 10px;
                font-family: $font-regular;
                color: $white;
                &.blue-theme{
                    background-color: $primary;
                }
                &.red-theme{
                    background-color: rgba(255,0,0,0.55);
                }
                &.yellow-theme{
                    background-color: rgba(255,255,0,0.5);
                    color: #333333;
                }
                &.green-theme{
                    background-color: rgba(0,255,221,0.9);
                    color: #333333;
                }
            }
            .add-information{
                color: red;
                font-family: $font-regular;
                font-size: 10px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 18px;
                text-align: center;
                display: flex;
                text-decoration: underline;
                cursor: pointer;
            }
            .bootom-box{
                border-top: 1px solid #EFEFEF;
                display: flex;
                margin-top: 10px;
                padding: 15px 0 0 0;
                &.no-border{
                    margin-top: 0px;
                    border-top: 0px solid #EFEFEF;
                    padding: 5px 0 0 0;
                }
                .child-item{
                    width: 50%;
                    color: #999999;
                    font-family: $font-regular;
                    font-size: 10px;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 18px;
                    text-align: center;
                    display: flex;
                    &.league-item{
                        width: 60%;
                    }
                    label{

                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;  
                    }
                    input[type=checkbox]{
                        background-color: #FA274E !important;
                        margin-right: 8px;
                        width: 16px;
                        height: 16px;
                        cursor: pointer;
                        &:checked{
                            background-color: #FA274E !important;
                        }
                    }
                }
            }
        }
    }
}