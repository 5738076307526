.announcement-container{
    padding: 50px 20px;
    .line-b {
        height: 1px;
        width: 100%;
        background-color: #D8D8D8;
      }
    .announcement-header{
        color: $black;
        font-family: $font-bold;
        font-size: 24px;
        font-weight: 800;
        letter-spacing: 0;
        line-height: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid #D8D8D8;
    }
    .announcement-parent{
        margin-top: 40px;
        .announcement-list-container{
            .left-item{
                width: 48%;
                height: auto;
                margin-right: 2%;
                border-radius: 10px;
                background-color: $white;
                box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
                position: relative;
                
            }
            .right-item{
                width: 48%;
                height: auto;
                margin-left: 2%;
                border-radius: 10px;
                background-color:$white;
                box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
                position: relative;
            }
            .list-header{
                padding: 25px 30px;
                color: $black;
                font-family: $font-bold;
                font-size: 24px;
                font-weight: 800;
                letter-spacing: 0;
                line-height: 30px;
            }
            .switch-label-btn {
                position: absolute;
                right: 120px;
                top: 15px;
                width: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
              
                span {
                  margin-right: 10px;
                }
              
                @media (max-width: 768px) {
                  top: 40px;
                  right: -30px;
                }
              }
            .add-btn{
                position: absolute;
                right: 20px;
                top: 20px;
                border: 1.52px solid #F8436E;
                border-radius: 3.05px;
                background-color: #F8436E;
                box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
                padding: 8px 30px;
                cursor: pointer;
                span{
                    padding-left: 10px;
                    color: $white;
                    font-family: $font-bold;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0;
                  //  line-height: 20px;
                    text-align: center;
                }
            }
            .banner-list{
                border-top: 1px solid #FFFFFF;
                text-align: center;
                overflow: scroll;
                overflow-y: scroll;
                white-space: nowrap;
                height: 1000px;
                .no-record{
                    font-size: 14px;
                    padding: 20px;
                    font-family: $font-regular;
                    margin-top: 20px;
                }
                .banner-item{
                    margin: 5px auto 0 auto;
                    border-radius: 10px;
                    background-color: #FFFFFF;
                    width: 100%; 
                    padding: 10px;
                    .no{
                        font-family: $font-bold;
                        font-size: 24px;
                        padding-top:5px;
                        @media(max-width:1400px){
                            font-size: 16px;

                        }                   
                     }
                   
                    &.row{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                    }
                     .banner-image-parent{
                        background-color: #EEEEEE;
                        margin-left: 20px;  
                        border-radius: 10px;
                        width: 80%;
                        overflow: hidden;
                        .banner-img{
                            border-radius: 7px;
                            margin: 20px;
                            width: 85%; 
                        }
                        .date-text{
                            color: #030F09;
                            font-size: 12px;
                            -webkit-line-clamp: 10;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            text-align: left;
                            white-space: initial;
                            word-break: break-word;
                            &.cursor-pointer{
                              cursor: pointer;
                            } 
                        }
                        .bottom-row{
                        padding: 0px 20px;
                         display: flex;
                         flex-direction: row;
                         justify-content: space-between;
                            .bottom-left{
                                display: flex;
                                flex-direction: row;
                                .label-text{
                                    color: #505251;
                                    font-size: 12px;
                                    font-family: $font-regular;
                                }
                                .date-text{
                                    color: #030F09;
                                    font-family: $font-regular;
                                }
                            }
                            .bottom-right{
                                display: flex;
                                flex-direction: row;   
                                img{
                                    width: 16px;
                                    height: 16px;
                                    margin: 5px;
                                    cursor: pointer;

                                } 
                            }
                            .click-count{
                                color: #505251;
                                font-size: 12px;
                                font-family: $font-regular;
                                span{
                                    color: #030F09;
                                    font-size: 12px;
                                    font-family: $font-bold;
                                }
                            }
                        }   
                        }
                }
                .banner-item:last-child{
                    margin-bottom: 20px;
                }
            }
        }
    }
}
