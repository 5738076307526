
// If you want to override variables do it here
@import "variables";
// Import styles with default layout.
// If you are going to use dark layout please comment next line
@import "@coreui/coreui-pro/scss/coreui.scss";
// Import styles with dark layout
// If you want to use dark layout uncomment next line
//@import "@coreui/coreui-pro/scss/themes/dark/coreui-dark";
// Temp fix for reactstrap
@import "@coreui/coreui-pro/scss/_dropdown-menu-right.scss";
// If you want to add something do it here
@import "custom";
// ie fixes
@import "ie-fix";
@import "fonts";
@import "icon";
@import "main";
@import "classess";

//component wise css files
@import "../views/UserManagement/Profile/PersonalDetails/PersonalDetails";
@import "../views/UserManagement/Manageuser/ManageUser.scss";
@import "../views/UserManagement/Wallet/Wallet.scss";
@import "../views/UserManagement/VerifyDocument/VerifyDocument.scss";
@import "../views/UserManagement/ChangeStatus/ChangeStatus.scss";
@import "../views/UserManagement/Profile/Dashboard/Dashboard.scss";
@import "../views/UserManagement/Profile/Profile.scss";
@import "../views/UserManagement/Profile/Transaction/Transaction.scss";
@import "../views/UserManagement/Profile/Gamestats/Gamestats.scss";
@import "../views/UserManagement/Profile/Referrals/Referrals.scss";
@import "../views/Marketing/CommunicationDashboard/CampaignDashboard.scss";
@import "../views/Marketing/UserSegmentation/NewCampaign.scss";
@import "./partial/header";
@import "./partial/sidebar";
// @import "../views/GameCenter/DFS/DFS.scss";
// @import "../views/GameCenter/DFS/FixtureSalaryReview.scss";
// @import "../views/GameCenter/DFS/Teams.scss";
// @import "../views/Contest/Contestlist.scss";
// @import "../views/Contest/Createtemplatecontest.scss";
// @import "../views/Contest/Createcontest.scss";
// @import "../views/ContestTemplate/Createtemplate.scss";
@import "../views/Dashboard/MainDashboard.scss";
// @import "../views/Contest/Contesttemplate.scss";
// @import "../views/Contest/Fixturecontest.scss";
// @import "../views/ManageScoring/ManageScoring.scss";
@import "../views/Cms/AppBanner.scss";
@import "../views/Cms/LobbyBanner.scss";
@import "../views/Cms/CMS.scss";
@import "../views/Cms/BackgroundImage.scss";
@import "../views/Cms/Summernote.scss";
@import "../views/MarketingReferral/ReferralAmount.scss";
@import "../views/MarketingReferral/PromoCode.scss";

@import "../views/UserManagement/Profile/LoginHistory/LoginHistory.scss";
@import "../views/Finance/WithdrawalList.scss";
@import "../views/Finance/TransactionList.scss";
@import "../views/Finance/ContestDetails.scss";
@import "../components/WithdrawlModal/WithdrawlModal.scss";
@import "../views/OtherPlatform/PlatformLogin.scss";
@import "../views/OtherPlatform/PlatformSiteMapping.scss";

@import "./expert_advice";
@import "./announcement";
@import "./create_announcement";
@import "./notifications";
@import "./lineupout";
@import "./add-match-information";
@import "./loader";
@import "./manage_user";
@import "./user-detail";
@import "./dashboard-view";
@import "../views/DashboardView/NewDashboard.scss";
@import "./tournament.scss";
@import "./simulator_contest";