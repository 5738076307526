.site-mapping-main {
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-evenly;

  @media (max-width:768px) {
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
  }
}

.site-mapping-header {
  position: relative;
  padding: 50px 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #D8D8D8;
  display: flex;

  .tab-item {
    color: $black;
    font-family: $font-bold;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 22px;
    margin-right: 10px;
    cursor: pointer;

    &.selected {
      border-bottom: 2px solid $primary;
      color: $primary;
    }
  }
}

.left-section-form {
  width: 100%;
  height: 128px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

  @media (max-width:768px) {
    margin-bottom: 20px;
    margin-top: 25px;
    width: 100%;
    height: 100%;
  }
}

.right-section-mapping {
  position: relative;
  width: 100%;
  height: 619px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 40px 40px 25px 40px;
  margin-top: 25px;

  @media (max-width:768px) {
    width: 100%;
  }

  .distribution-div {
    display: flex;
    justify-content: space-between;
  }
}

.rt-leftsect,
.rt-rightsect {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;

  .form-inputs {
    width: 49%;
  }

  .form-inputs-deselect {
    width: 49%;
  }
}


.min-height {
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    color: #212121;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }
}

.bold-txt {
  color: #555555;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  width: 50%;
}

.mt-20p {
  margin-top: 50px;
  height: 389px;
  overflow-y: auto;
}

.mt-3p {
  margin-top: 40px;
}

.switch-label {
  position: absolute;
  right: 15px;
  top: 45px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    top: 40px;
    right: -30px;
  }
}

.session-form-mapping {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 128px;
  margin: 10px 0 25px 0;

  @media (max-width: 768px) {
    height: 100%;
    flex-direction: column;
    padding: 15px;
  }
}

.otp-btn-divmp {
  width: 25%;
  width: 184px;
  // display: flex;// align-items: center;
}

.logout-btn-mp {
  margin-top: 8px;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #F8436E !important;
  color: #F8436E;

  @media (max-width:1366px) {
    margin-top: 20px;
    width: 100%;
  }
}

.form-grp-mp {
  width: 31.25%;

  @media (max-width:768px) {
    width: 100%;
  }
}

.pink-text {
  color: #FA274E;
  // font-family: Muli;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 35px;
}

.pd-rl100 {
  padding: 0 60px;

  @media (max-width:768px) {
    padding: 0;
  }
}

.border-linebt {
  border-bottom: 1px solid #979797;
  height: 44px;
  padding-left: 20px;
}

.border-top-pp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #979797;
  margin-top: 10px;

  .mt-3p {
    margin-top: 10px;
    width: 184px;
  }
}

.white-bg-fa {
  background-color: #FAFAFA;
  padding: 20px 0 0 0;
}

.switch-onoff {
  display: flex;
  align-items: center;

  span {
    margin: 0 5px;
    font-size: 18px;
    color: #212121;
    letter-spacing: 0;
    line-height: 20px;
  }
}

.dflx-mpd {
  display: flex;
  flex-direction: column;
  position: relative;
  right: -75%;
  top: -40px;

  @media (max-width: 768px) {
    right: 0%;
    top: -80px;
  }

  @media (max-width: 1440px) {
    right: -70%;
    top: -44px;

  }
}