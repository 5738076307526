.login-form-ct {
    height: 90vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-centre {
    height: auto;
    width: 30%;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

    @media (max-width:1100px) {
        width: 95%;
    }
}

.session-form {
    padding: 10.96% 16.66%;

    @media (max-width:1366px) {
        padding: 7.96% 7.66%;
    }
}

.otp-input {
    // width: 45.83%;
    display: flex;
    justify-content: space-between;


    input {
        width: 40px !important;
        height: 40px !important;
        background-color: #EDEDED !important;
        border: 1px solid #D2DFEC !important;
        border-radius: 4px !important;
    }

}

.otp-container {
    width: 100%;
    // display: flex;
    margin-top: 25px;

    div {
        align-items: center;
        justify-content: space-evenly;
    }
}

.otp-label {
    margin-left: 10.41%;
}

.otp-btn-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sendotp-btn,
.sendotp-btn:focus,
.sendotp-btn:hover,
.sendotp-btn:not(:disabled):not(.disabled):active,
.sendotp-btn:not(:disabled):not(.disabled):hover {
    background-color: #FFFFFF;
    color: #F8436E;
    outline: none;
    box-shadow: none;
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
}

.blk-underlinex {
    text-decoration: none;
    color: #555655 !important;

    .resend-text {
        color: #C40000;
    }
}

.login-btn-pt {
    margin-top: 8px;
    width: 25%;
}

.logout-btn-pt {
    margin-top: 8px;
    width: 28%;
    background-color: #FFFFFF;
    border: 1px solid #F8436E !important;
    color: #F8436E;

    @media (max-width:1366px) {
        margin-top: 20px;
        width: 40%;
    }
}

.labels-form {
    color: #555555;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
}

.form-inputs {
    border: 1px solid #D2DFEC !important;
    border-radius: 4px !important;
    background-color: #EDEDED !important;
    color: #212121;
    font-weight: bold;
}
.form-inputs-deselect {
    border: 1px solid orange !important;
    border-radius: 4px !important;
    background-color: #EDEDED !important;
    color: #212121;
    font-weight: bold;
}
.options-form {
    color: #212121;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
}

.pos-active-inactive {
    position: relative;
}

.pos-absolute-txt {
    position: absolute;
    top: 53%;
    left: 63%;
    z-index: 1000;
    font-size: 14px;
    // font-weight: bold;
    color: #C40000;
    pointer-events: none;
    @media (max-width:1440){
        left: 60%;
    }
}

.d11-active {
    color: #209C0B;
}