.common-table {
  .table {
    margin-bottom: 20px;

    thead {
      border: none;
      background-color: #2b2e47;
      box-shadow: 0 13px 26px 0 rgba(0, 0, 0, 0.1);
      color: $white;
    }

    th {
      padding: 4px 8px;
      font-family: $font-bold;
      border: none;
      vertical-align: middle;
      // white-space: nowrap;
    }

    tbody {
      border-top: none;
      background-color: $white;
      box-shadow: 0 13px 26px 0 rgba(0, 0, 0, 0.1);

      &:nth-child(odd) {
        background-color: #efefef;
      }

      td {
        border: none;
        color: #030f09;
        font-family: $Regular;
        padding: 10px;
        font-size: 16px;
        text-align: left;
        vertical-align: middle;
        // white-space: nowrap;
      }
    }

    .left-th {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .right-th {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .linup-details {
      box-sizing: border-box;
      height: 30px;
      width: 100px;
      border: 1px solid #999999;
      border-radius: 18px;
      font-size: 12px;
      padding: 6px 10px;
      color: $gray-9;
      cursor: pointer;
      @media (max-width: 1280px) {
        font-size: 10px;
      }
      &.active {
        border-color: $secondary-color;
        color: $secondary-color;
      }
    }
  }
}

.stats-graph {
  // min-height: 300px;
  min-height: 350px;
  height: 350px;
  width: 100%;
  border-radius: 6px;
  background-color: $white;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.06);
  margin-top: 10px;
  margin-bottom: 20px;
  @media (max-width : 1330px) {
      min-height: 277px;
  }
  .graph-title {
    font-family: $font-bold;
    font-size: 16px;
    padding: 20px 10px;
  }
}

  .st-height{
    height: 300px;
    min-height: 300px;
    @media (max-width: 1330px) {
        height: 277px;
        min-height: 277px;
      }
  }

.gamestats {
  margin-top: 15px;

  .filter-right-box {
    margin-top: 20px;
  }

  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    input{
      outline: none;
    }
  }
}

.lineup-details {
  .lineup-teams {
    padding: 10px 16px;
    color: $white;
  }

  .modal-body {
    padding: 10px 20px;
  }

  .lineup-feelist {
    padding-inline-start: 0px;
    margin-bottom: 0;
    padding-top: 12px;
  }

  .lineup-feeitem {
    display: inline-block;
    margin-right: 30px;

    label {
      margin-bottom: 0;
      opacity: 0.5;
      font-family: $font-bold;
      font-size: 12px;
      min-width: 122px;
    }
  }

  .rank-box {
    padding: 15px;
  }

  .table {
    margin-bottom: 0;

    thead {
      font-size: 12px;
    }

    th {
      padding: 2px 6px;
      border-bottom: none;
    }

    tbody td {
      padding: 6px;
      font-size: 14px;
    }
  }

  .points-box {
    float: right;
    padding: 30px 48px;
  }

  .points-title {
    color: #a8a8a8;
    font-family: $font-bold;
    font-size: 16px;
    margin-right: 42px;
  }

  .total-points {
    font-family: $font-bold;
    font-size: 18px;
  }

  .table-responsive {
    max-height: 400px;
  }

  .menu-wrapper {
    border-bottom: 1px solid rgba(153, 153, 153, 0.25);
  }
  .menu-wrapper--inner {
    // transform: translate3d(20px, 0px, 0px) !important;
  }
  .menu-item-wrapper {
    outline: none;
    &.active::after {
      content: "";
      display: block;
      width: 100%;
      border-bottom: 2px solid $secondary-color;
      margin-top: 10px;
    }
  }

  .menu-item {
    margin: 16px 20px 0;
    user-select: none;
    cursor: pointer;
    border: none;
    opacity: 0.8;
    color: $gray-9;
    font-family: $font-bold;
    &.active {
      opacity: 1;
      color: $black;
    }
  }

  .scroll-menu-arrow {
    padding: 20px;
    cursor: pointer;
  }
}

.pointer{
  cursor: pointer;
}