.loader-back{ 
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100vh;
    z-index: 9999999;
    background-color: rgba(0,0,0,0.8);
    max-width: 100%;
    svg{
      color: $primary;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .msg{
      position: absolute;
      left: 50%;
      color: #FFFFFF;
      opacity: 0.8;
      font-size: 16px;
      top: 50%;
      transform: translate(-50%, 40px);
    }
  }
  