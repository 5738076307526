.expert-advice-container{
    padding: 50px 20px;

    .upcominfixture-header{
        color: #555555;
        font-family:  $font-bold;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
    }
    .Select-control{
        border-radius: 25px !important;
        margin-top: 10px  !important;
    }
    .advice-header{
        color: #999999;
        font-family:  $font-bold;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 30px;
        margin: 40px 0;
    }
    .selected-fixture{
        color: #111111;
        font-family:  $font-bold;
        font-size: 24px;
        font-weight: 800;
        letter-spacing: 0;
        line-height: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid #D8D8D8;
    }
    .create-advice-parent{
        margin-top: 40px;
        border-radius: 10px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
        .create-advice-form-container{
            width: 100%;
            min-height: 300px;
            .inner-container{

                padding: 56px 24px;
                .child-item{
                    margin-top: 14px;
                    .title{
                        color: #555555;
                        font-family:  $font-bold;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 20px;
                    }
                    .input-style{
                        height: 40px;
                        border: 1px solid #D2DFEC;
                        border-radius: 4px;
                        background-color: #EDEDED;
                        padding: 0 10px;
                        width: 100%;
                        font-family: $font-regular;
                    }
                    .input-description-style{
                        height: auto;
                        min-height: 40px;
                        max-height: 110px;
                        border: 1px solid #D2DFEC;
                        border-radius: 4px;
                        background-color: #EDEDED;
                        padding: 0 10px;
                        width: 100%;
                        font-family: $font-regular;
                    }
                    textarea{
                        resize: none;
                    }
                    .btn-style{
                        margin-top: 20px;
                        box-sizing: border-box;
                        border: 1.52px solid #F8436E;
                        border-radius: 3.05px;
                        box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
                        background-color: transparent;
                        span{
                            color: #222222;
                            font-family: $font-bold;
                            font-size: 14px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 20px;
                            text-align: center;
                        }
                    }
                }
            }
        }
        .post-item{
            padding: 56px 24px;
            .top-row{
                padding-right: 20px;
                i{
                    cursor: pointer;
                }
            }
            display: inline-table;
            background-color: #FFFFFF;
            margin-right: 50px;
            .title{
                color: #212121;
                font-family:$font-bold;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 19px;
                white-space: pre-wrap;
            }
            .description{
                width: 100%;
                color: #999999;
                font-family:$font-regular;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 18px;
                white-space: pre-wrap;
                padding: 10px 16px;
            }
            .site-meta-container{
                background-color: #F2F2F2;
                border-radius: 10px;
                padding-bottom: 20px;
                margin-top: 10px;
                img{
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    height: 130px;
                    object-fit: initial;
                    width: 100%;
                }
                .meta-title{
                    margin-top: 16px;
                    color: #212121;
                    font-family:$font-bold;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 19px;
                    white-space: pre-wrap;
                    padding: 0 40px;
                }
                .meta-description{
                    margin-top: 16px;
                    color: #999999;
                    font-family:$font-regular;
                    font-size: 14px;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 14px;
                    white-space: pre-wrap;
                    padding: 0 40px;
                }
                .meta-url{
                    margin-top: 16px;
                    color: #999999;
                    font-family:$font-regular;
                    font-size: 10px;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 13px;
                    white-space: pre-wrap;
                    padding: 0 40px;
                }

            }
        }
    }
    .upcomin-post-header{
        color: #111111;
        font-family:  $font-bold;
        font-size: 24px;
        font-weight: 800;
        letter-spacing: 0;
        line-height: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid #D8D8D8;
        margin-top: 40px;
    }
    .upcoming-post-container{
        margin-top: 40px;
        background-color: transparent;
        display: block;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        min-height: 400px;
        padding-bottom: 30px;
        .post-item{
            .top-row{
                padding-right: 20px;
                i{
                    cursor: pointer;
                }
            }
            display: inline-table;
            box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
            border-radius: 10px;
            width: 410px;
            max-width: 410px;
            min-height: 400px;
            background-color: #FFFFFF;
            margin-right: 50px;
            padding: 25px 16px;
            .title{
                color: #212121;
                font-family:$font-bold;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 19px;
                white-space: pre-wrap;
            }
            .description{
                width: 100%;
                color: #999999;
                font-family:$font-regular;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 18px;
                white-space: pre-wrap;
                padding: 10px 16px;
            }
            .site-meta-container{
                background-color: #F2F2F2;
                border-radius: 10px;
                padding-bottom: 20px;
                margin-top: 10px;
                img{
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    height: 130px;
                    object-fit: initial;
                    width: 100%;
                }
                .meta-title{
                    margin-top: 16px;
                    color: #212121;
                    font-family:$font-bold;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 19px;
                    white-space: pre-wrap;
                    padding: 0 40px;
                }
                .meta-description{
                    margin-top: 16px;
                    color: #999999;
                    font-family:$font-regular;
                    font-size: 14px;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 14px;
                    white-space: pre-wrap;
                    padding: 0 40px;
                }
                .meta-url{
                    margin-top: 16px;
                    color: #999999;
                    font-family:$font-regular;
                    font-size: 10px;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 13px;
                    white-space: pre-wrap;
                    padding: 0 40px;
                }

            }

            &.slide-demo{
                padding: 56px 24px;
            }
        }
    }
}