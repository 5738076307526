.promocode-view {
  .search-box {
    width: 250px;
    float: right;
  }
  .react-datepicker-wrapper {
    vertical-align: top;
  }
  .detail-view {
    text-decoration: underline !important;
    cursor: pointer;
    margin-bottom: 10px;
    display: block;
  }
}
.promocode-add-view {
  background-color: $white;
  padding: 40px 20px;
  margin-top: 20px;
  border-radius: 4px;
  .radio-element {
    float: left;
    width: 100px;
  }
  label {
    font-family: "MuliBold";
    margin-top: 12px;
  }
  .form-control,
  .Select-control {
    height: 42px;
  }
  .Select-control {
    border-color: #e4e7ea;
  }
  .Select-placeholder,
  .Select-value-label {
    line-height: 42px;
  }
  // .Select-arrow-zone {
  //   display: none;
  // }
  .icon-Shape {
    font-size: 16px;
    color: $gray-9;
    position: absolute;
    top: 0;
    right: 0;
    padding: 13px 46px 13px 10px;
    width: 42px;
    height: 42px;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  input[type="radio"] {
    z-index: 1;
    margin-top: 12px;
    left: 2px;
    top: 5px;
    cursor: pointer;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.user-deposit-amount {
  .search-box {
    .form-control {
      border-color: #ccc;
    }
  }
  .react-datepicker-wrapper {
    display: block;
    .react-datepicker__input-container {
      .form-control {
        border-color: #ccc;
      }
    }
  }
}
