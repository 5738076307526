.dashboard-main {
  margin-top: 40px;
  .name-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 130px;
    display: block;
    padding: 5px;
    text-align: left;
  }
  .to-seprate {
    margin: 0 8px;
  }
  .icon-info {
    cursor: pointer;
    opacity: 0.5;
  }
  .custom-graph {
    span {
      display: inline-block;
      height: 18px;
      color: $black;
      font-family: $font-bolditalic;
      text-align: center;
      padding: 7px;
      margin: 0 4px;
      cursor: pointer;
      &.active {
        color: $white;
        height: 32px;
        border-radius: 4px;
        background: $secondary-color;
      }
    }
  }
  span.rupee-btn {
    height: 22px;
    width: 28px;
    border-radius: 4px;
    background: $secondary-color;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    color: $white;
    font-family: $font-bold;
    font-size: 20px;
    text-align: center;
    padding: 0;
  }
  .no-data-img {
    width: 120px;
    height: 104px;
    opacity: 0.5;
  }
  @media (max-width: 1280px) {
    .graph-align {
      margin-bottom: 10px;
    }
  }
  .date-filter {
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .top-rank-box {
    
  }
}
