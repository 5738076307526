.notifications-container{
    padding: 50px 20px;
    .line-b {
        height: 1px;
        width: 100%;
        background-color: #D8D8D8;
      }
    .notifications-header{
        color: $black;
        font-family: $font-bold;
        font-size: 24px;
        font-weight: 800;
        letter-spacing: 0;
        line-height: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid #D8D8D8;
        &.create{
        font-weight: normal;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        }
    }
    .notifications-table-header{
        height: 36px;
        width: 100%;
        padding: 5px;
        border-radius: 6px;
        background-color: #2B2E47;
        display: flex;
        margin-top: 30px;

        .label{
            color: #FFFFFF;
           font-family:$font-bold;
           font-style: normal;
            align-self: center;
            padding-left: 10px;
            width: 15%;
            &.w30{width: 30%;}
            &.w20{width: 20%;}
            &.w10{width: 10%;}
            &.w25{width: 25%;}
            &.row-c{
                display: flex;
                flex-direction: row;
               justify-content: center;
           }           

        }
}
    .notifications-parent{
        margin-top: 10px;
        .notification-row{
            width: 100%;
            display: flex;
            flex-direction: row;
            background-color: #FFFFFF;
            box-shadow: 0 4px 16px 0 rgba(0,0,0,0.06);
            padding: 5px;
             &.invert-color{
                background-color: #EFEFEF;
                box-shadow: 0 4px 16px 0 rgba(0,0,0,0.06);
             }

            .notification-colum{
                img{
                    margin: 5px;
                }
                .noti-img{
                    width: 50px;
                    height: 50px;
                }
                font-family:$font-bold;
                align-self: center;
                padding-left: 10px;
                width: 15%;
                display: flex;
                &.w30{width: 30%;}
                &.w20{width: 20%;}
                &.w10{width: 10%;}
                &.w25{width: 25%;}

                &.row-c{
                     flex-direction: row;
                    justify-content: center;
                }
                &.col-c{
                    flex-direction: column;
                }
            }
            .big-text {
                color: #555555;
                font-family: $font-regular;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
              }
              .small-text {
                color: #555555;
                font-family: $font-regular;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
              }
        }
        }
    
}

.add-global-btn-pr{
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 15px;
}
.add-global-btn{
   
    border: 1.52px solid #F8436E;
    border-radius: 3.05px;
    background-color: #F8436E;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
    padding: 8px 30px;
    cursor: pointer;
    &.ml20{
     margin-left: 20px;
    }
    span{
        padding-left: 10px;
        color: $white;
        font-family: $font-bold;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
      //  line-height: 20px;
        text-align: center;
    }
}
.notification-box{
  height: 1439px;
  width: 100%;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
  margin-top: 20px;
  .target {
    color: #111111;
    font-family: $font-bold;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 35px;
    padding-bottom: 10px;
  }
  .create-notification-parent{
     .create-notification-form-container{
        width: 100%;
      //  min-height: 300px;
        .inner-container{

            padding: 3px 24px;
            .child-item{
                .send-to-eligible-user {
                    color: #555555;
                    font-family:$font-bold;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 20px;
                  }
                    .Select-control{
                        background-color: #cbc6c6; 
                        border: 1px solid #D2DFEC;
                        border-radius: 4px;
                        background-color: #EDEDED;
                        box-sizing: border-box;
                        .Select-clear{
                         display:none;
                        }
                }

                .time-picker{
                .ant-picker{
                    background-color: #EDEDED; 
                    border: 1px solid #D2DFEC;
                    box-sizing: border-box;
                border-radius:5px;
                padding: 7px 40px;
                margin-top: -3px;               }
                }
                .date-picker{
                .react-datepicker-wrapper{
                    display: block;
                    border-radius: 5px;
                    .react-datepicker__input-container{
                        input{
                            
                                font-size: 14px;
                                width: 100%;
                                border-style: solid;
                                border-radius: 4px;
                                border-color: #c9c6c6;
                                border-width: 1px;
                                padding: 6px 20px;
                                margin-top: -1px; 
                                background-color: #EDEDED;
                                border: 1px solid #D2DFEC;
                                box-sizing: border-box;                      
                        }
                    }
                }
            }
                margin-top: 14px;
                &.b{
                    border-bottom: 1px solid #D8D8D8;
                }
                .title{
                    color: #555555;
                    font-family:  $font-bold;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 20px;
                }
                .input-style{
                    height: 40px;
                    border: 1px solid #D2DFEC;
                    border-radius: 4px;
                    background-color: #EDEDED;
                    padding: 0 10px;
                    width: 100%;
                    font-family: $font-regular;
                }
                .input-style-chk{
                    align-self: center;
                    margin-left: 10px;
                    width: 20px;
                    height: 20px;
                    
                    }
                .chk-label{
                    font-family: $font-bold;
                    padding: 0px 10px;

                }
                .input-description-style{
                    height: auto;
                    min-height: 40px;
                    max-height: 110px;
                    border: 1px solid #D2DFEC;
                    border-radius: 4px;
                    background-color: #EDEDED;
                    padding: 0 10px;
                    width: 100%;
                    font-family: $font-regular;
                }
                textarea{
                    resize: none;
                }
                .btn-style{
                    margin-top: 20px;
                    box-sizing: border-box;
                    border: 1.52px solid #F8436E;
                    border-radius: 3.05px;
                    box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
                    background-color: transparent;
                    span{
                        color: #222222;
                        font-family: $font-bold;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 20px;
                        text-align: center;
                    }
                }
            }
        }
    }
    .post-item{
        padding: 56px 24px;
        .top-row{
            padding-right: 20px;
            i{
                cursor: pointer;
            }
        }
        display: inline-table;
        background-color: #FFFFFF;
        margin-right: 50px;
        .title{
            color: #212121;
            font-family:$font-bold;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 19px;
            white-space: pre-wrap;
        }
        .description{
            width: 100%;
            color: #999999;
            font-family:$font-regular;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 18px;
            white-space: pre-wrap;
            padding: 10px 16px;
        }
        .site-meta-container{
            background-color: #F2F2F2;
            border-radius: 10px;
            padding-bottom: 20px;
            margin-top: 10px;
            img{
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                height: 130px;
                object-fit: initial;
                width: 100%;
            }
            .meta-title{
                margin-top: 16px;
                color: #212121;
                font-family:$font-bold;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 19px;
                white-space: pre-wrap;
                padding: 0 40px;
            }
            .meta-description{
                margin-top: 16px;
                color: #999999;
                font-family:$font-regular;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 14px;
                white-space: pre-wrap;
                padding: 0 40px;
            }
            .meta-url{
                margin-top: 16px;
                color: #999999;
                font-family:$font-regular;
                font-size: 10px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 13px;
                white-space: pre-wrap;
                padding: 0 40px;
            }

        }
    }
}
}
.image-view-parent{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 20px;

}
.image-row{
    box-shadow: 0 4px 20px 0 #dbdbdb;
    border-radius: 8px;
    background-color: #FFFFFF;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 5px;
        .group {
            width: 100%;
            display: flex;
    justify-content: space-between;
    height: 46px;
    //width: 300px;
    border-radius: 0 0 8px 8px;
    background-color: #c9c7c7;
    align-items: center;
    padding: 10px;        }
    .img-name{
        cursor: pointer;
        color: #2B2E47;
        font-family: $font-bold;
        font-size: 12px;
    }
          .img {
            border-radius: 4px;
            margin: 10px;
            width: 90%;

          }
          .delimg {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        
}
.no-record{
    text-align: center;
    padding: 50px;
 }
 .mt20{
   margin-top: 20px;
 }