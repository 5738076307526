.filter-right-box {
  margin-top: 30px;
}

.filter-box {
  position: relative;
  float: right;

  i:not(.icon-export) {
    position: absolute;
    top: 0;
    left: 0;
    color: black;
    font-size: 16px;
    z-index: 1;
    padding: 8px;
  }
}

.filter-export {
  float: right;
  box-sizing: border-box;
  height: 30px;
  width: 38px;
  border: 1px solid rgba(142, 142, 142, 0.5);
  border-radius: 4px;
  padding: 1px 9px;
  margin-left: 6px;
  background-color: $white;
  cursor: pointer;

  i {
    font-size: 18px;
  }
}

.transaction {
  margin-top: 15px;
  margin-bottom: 30px;
  .desc-width{
    width: 400px;
  }
  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    :active,
    :focus {
      outline: none;
    }
  }

  .Select {
    height: 30px;
    width: 78px;
    border-radius: 4px;
  }

  .Select-control,
  .Select-multi-value-wrapper {
    height: 30px;
    border-color: rgba(142, 142, 142, 0.5);
    .Select-clear-zone{
      display: none;
    }
  }

  .Select-control{
    .Select-value{
      padding-right : 0 !important;
    }
  }

  .Select-arrow-zone {
    display: none;
  }

  .Select-placeholder {
    // right: 0 !important;
    // left: auto;
    color: $text-color;
    font-family: $font-bold;
    font-size: 12px;
    cursor: pointer;
  }

  .calculations-list {
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
  }

  .calculations-sign {
    display: inline-block;
    margin: 20px;
    vertical-align: top;
    color: $gray-9;
    font-family: $Regular;
    font-size: 16px;
    height: 26px;
  }

  .calculations-item {
    display: inline-block;
    box-sizing: border-box;
    height: 66px;
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    background-color: $white;
    padding: 10px 20px;
    @media (max-width : 1330px) {
      padding: 10px 10px;
    }
    label {
      margin-bottom: 0;
      font-family: $Regular;
    }
  }

  .calculations-seprator {
    display: inline-block;
    border: 1px solid #D8D8D8;
    // background: linear-gradient(136.05deg, #F5F7FA 0%, #F4F6F8 100%);
    margin: 0 20px;
    height: 64px;
    vertical-align: bottom;
  }

  .numbers {
    font-family: $font-bold;
    font-size: 20px;
    line-height: 1.3
  }

  .width-305 {
    width: 305px;
    @media (max-width : 1330px) {
      width: 280px;
    }
    @media (max-width : 1175px) {
      width: 230px;
    }
  }

  .width-148 {
    width: 148px;
  }

  .width-108 {
    // width: 108px;
    background-color: #e7e7e7;
  }

  .total-deposit {
    float: left;
    margin-right: 64px;
    @media (max-width : 1330px) {
      margin-right: 34px;
    }
    @media (max-width : 1175px) {
      margin-right: 14px;
    }
  }

  .table {
    thead {
      font-family: $font-bold;
      color: $white;
      height: 56px;
      background-color: #2B2E47;
      box-shadow: 0 13px 26px 0 rgba(0, 0, 0, 0.1);

      th {
        padding: 4px;
        border: none;
        vertical-align: middle;
      }
    }

    tbody {
      height: 50px;
      border-top: none;
      background-color: #FFFFFF;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.06);

      &:nth-child(odd) {
        background-color: #EFEFEF;
      }

      td {
        border-top: none;
        padding: 12px;
        color: $text-color;
        font-family: $Regular;
        font-size: 16px;
      }
    }

    .balance-type {
      th {
        opacity: 0.5;
        padding: 0 20px 8px;
      }
    }
  }
}
