.create-announcement {
    text-align: center;
    max-width: 550px !important;
    margin-top: 100px;
    .box-content {
      width: 523px;
      margin: 0 auto;
      @media (max-width: 751px) {
        width: 400px;
        padding: 20px;
      }
    }
    .close-btn{
        position: absolute;
        right: 20px;
        top: 15px;
        cursor: pointer;
    }
    .model-body-container{
        text-align: left;
        padding: 30px;
        .upload-title{
            color: #555555;
            font-family: $font-bold;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 20px;
        }
        .upload-image-container{
            margin: 20px 0;
            border: 1px dashed #212121;
            border-radius: 4px;
            border-radius: 10px;
            background-color: #FFFFFF;
            box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
            min-height: 200px;
            text-align: center;
            .small-thumb-style{
               // width: 80px;
                margin: 50px 0 0 0;
                max-width: 400px;
                &.add-image{
                    width: 400px;
                }
            }
            .add-image-label{
                margin: 30px;
                color: #999999;
                font-family: $font-regular;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 18px;
                text-align: center;
                span{
                    color: #017AFF;
                    cursor: pointer;
                }
            }
        }
        .add-url-label{
            color: #555555;
            font-family: $font-bold;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 15px;
            span{
                font-size: 10px;
                color: #ACACAC;
            }
        }
        .input-style{
            height: 40px;
            border: 1px solid #D2DFEC;
            border-radius: 4px;
            background-color: #EDEDED;
            padding: 0 10px;
            width: 100%;
            font-family: $font-regular;
            margin: 20px 0;
        }
        .submit-btn-style{
            width: 120px;
            margin: auto;
            border: 1.52px solid #F8436E;
            border-radius: 3.05px;
            box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
            text-align: center;
            padding: 10px;
            cursor: pointer;
            span{
                color: #222222;
                font-family: $font-regular;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 20px;
                text-align: center;
            }
        }
        .remove-btn-style{
            width: 120px;
            margin: 10px;
            background-color: #F8436E;
            border-radius: 5px; 
           text-align: center;
            padding: 5px;
            cursor: pointer;
            span{
                color: #FFFFFF;
                font-family: $font-bold;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 20px;
                text-align: center;
            }
        }
    }
}