.box33 {
    height: 326px;
    padding: 10px 5px 0 18px;
    background: #fff;
    border-radius: 5px;
    margin-top: 15px;
    
    .boxhead { 
        font-weight: 600;
    }
}
.login-filter {
    text-align: right;
    margin-top: 10px;
    padding: 5px;
    .filtertext {
        padding: 5px 10px 5px 10px;
        background: #fff;
        border-radius: 5px;
    }
}
.login-table{ 

    min-height: 349px;
    background: #fff;
    padding: 6px 5px 6px 5px;
    margin-top: 9px;
    border-radius: 5px;

}
