.dashboard-view-container{
    padding: 50px 20px;
    .dashboard-view-parent{
        margin-top: 40px;
        .dashboard-view-heading {
            display: block;
            margin-bottom: 20px;
            margin-top: 26px;
          
            .page-title {
              float: left;
              color: #030f09;
              font-family: $font-muliblack;
              font-size: 32px;
              margin: 0;
            }
          
            .search-user {
              position: relative;
              float: right;
              border-radius: 18px;
          
              :focus {
                outline: none;
              }
          
              .search-input {
                height: 36px;
                width: 311px;
                border-radius: 18px;
                background-color: #ffffff;
                box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
                border: none;
                padding: 9px 18px;
                font-family: $font-bold;
                color: $gray-9;
              }
          
              i {
                position: absolute;
                top: 0;
                right: 0;
                padding: 11px 18px;
                font-size: 15px;
                cursor: pointer;
                color: #666666;
              }
            }
        }
        .top-static-box{
            width: 100%;
            margin: 20px 0;
            display: flex;
            flex-direction: row;
            .top-static-left-box{
              width: 58%;
              padding: 20px;
              margin-right: 2%;
              display: flex;
              position: relative;
              flex-direction: column;
              .filter-box{
                display: flex;
                flex-direction: row;
                margin-bottom: 20px;
                .filter-item{
                  margin-right: 20px;
                  .filter-label {
                    color: #555555;
                    font-family: $font-bold;
                    font-style: normal;
                    margin-bottom: 4px;
                    display: block;
                    min-width: 50px;
                    min-height: 21px;
                  }
                  .filter-date {
                    width: 100px;
                    height: 36px;
                    border-radius: 18px;
                    border: none;
                    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
                    text-align: center;
                    font-family: $font-bold;
                    color: $gray-9;
                    font-size: 14px;
                    cursor: pointer;
                  }
                  .clear-date-filter{
                    margin-top: 10px;
                    text-align: center;
                    font-family: $font-bold;
                    color: $gray-9;
                    font-size: 14px;
                    cursor: pointer;
                    text-decoration: underline;
                  }
                }
              }
            }
            .top-static-right-box{
              width: 40%;
              height: 260px;
              margin-top: 81px;
              padding: 20px;
            }
            .all-count-container{
              width: 98%;
              padding: 20px;
              border-radius: 8px;
              margin-bottom: 20px;
              background-color: $white;
              .header{
                width: 100%;
                text-align: center;
                color: #999999;
                font-size: 18px;
                font-family: $font-bold;
              }
              .header-value{
                width: 100%;
                text-align: center;
                color: #212341;
                font-size: 42px;
                font-weight: 900;
                font-family: $font-bold;
                margin-bottom: 0px;
              }
              .row{
                width :100%;
                padding: 20px;
                .label-text{
                  color: #999999;
                  font-size: 14px;
                  font-family: $font-regular;
                  text-align: center;
                  text-transform: uppercase;
                }
                .value{
                    color: #212341;
                    font-size: 20px;
                    text-align: center;
                    font-family: $font-bold;
                }
              }
            }
        }
        .top-revenue-box{
        width: 40%;
        margin: 20px auto;
        display: flex;
        flex-direction: row;
        padding: 20px;
        border-radius: 8px;
        background-color: $white;
        .header{
            width: 100%;
            text-align: center;
            color: #999999;
            font-size: 18px;
            font-family: $font-bold;
            line-height: 60px;
        }
        .header-value{
            width: 100%;
            text-align: center;
            color: #212341;
            font-size: 42px;
            line-height: 60px;
            font-weight: 900;
            font-family: $font-bold;
        }
        }
    }
}